import {
  CheckboxItem,
  Content,
  Group,
  Item,
  ItemIndicator,
  Label,
  Portal,
  RadioGroup,
  RadioItem,
  Root,
  Separator,
  Sub,
  SubContent,
  SubTrigger,
  Trigger,
} from "@radix-ui/react-dropdown-menu";
import { Check, ChevronRight, Circle } from "lucide-react";
import {
  forwardRef,
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type ReactNode,
} from "react";
import { BsCheck } from "react-icons/bs";

import cn from "../../util/cn.js";
import Link from "../Link/Link.js";
import NavLink from "../Link/NavLink.js";

const DropdownMenu = Root;
const DropdownMenuTrigger = Trigger;
const DropdownMenuGroup = Group;
const DropdownMenuPortal = Portal;
const DropdownMenuSub = Sub;
const DropdownMenuRadioGroup = RadioGroup;

const DropdownMenuSubTrigger = forwardRef<
  ElementRef<typeof SubTrigger>,
  ComponentPropsWithoutRef<typeof SubTrigger> & {
    inset?: boolean;
    className?: string;
  }
>(({ className, inset, children, ...props }, ref) => (
  <SubTrigger
    ref={ref}
    className={cn(
      "focus:bg-slate-100 data-[state=open]:bg-slate-100 dark:focus:bg-slate-700 dark:data-[state=open]:bg-slate-700 flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm font-medium outline-none",
      inset && "pl-8",
      className
    )}
    {...props}
  >
    {children}
    <ChevronRight className="ml-auto h-4 w-4" />
  </SubTrigger>
));
DropdownMenuSubTrigger.displayName = SubTrigger.displayName;

const DropdownMenuSubContent = forwardRef<
  ElementRef<typeof SubContent>,
  ComponentPropsWithoutRef<typeof SubContent> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <SubContent
    ref={ref}
    className={cn(
      "text-slate-700 z-50 min-w-[8rem] overflow-hidden rounded-md  border  p-1 shadow-md animate-in slide-in-from-left-1",
      className
    )}
    {...props}
  />
));
DropdownMenuSubContent.displayName = SubContent.displayName;

const DropdownMenuContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content> & {
    className?: string;
    sideOffset?: number;
  }
>(({ className, sideOffset = 4, ...props }, ref) => (
  <Portal>
    <Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        " z-50 min-w-[12rem] overflow-hidden rounded-md border bg-surface p-1 shadow-md backdrop-blur-md animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2 ",
        className
      )}
      {...props}
    />
  </Portal>
));
DropdownMenuContent.displayName = Content.displayName;

const DropdownMenuItem = forwardRef<
  ElementRef<typeof Item>,
  ComponentPropsWithoutRef<typeof Item> & {
    inset?: boolean;
    className?: string;
  }
>(({ className, inset, ...props }, ref) => (
  <Item
    ref={ref}
    className={cn(
      "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm  outline-none focus:bg-black/5 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ",
      inset && "pl-8",
      className
    )}
    {...props}
  />
));
DropdownMenuItem.displayName = Item.displayName;

const DropdownMenuLinkItem = forwardRef<
  ElementRef<typeof Item>,
  ComponentPropsWithoutRef<typeof Item> & {
    inset?: boolean;
    to: string;
    icon?: ReactNode;
    postTo?: boolean;
    className?: string;
  }
>(({ className, inset, children, to, icon, postTo, ...props }, ref) => (
  <Item
    asChild
    ref={ref}
    className={cn(
      "relative flex select-none items-center rounded-sm  text-sm  outline-none focus:bg-black/5 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ",
      inset && "pl-8",
      className
    )}
    {...props}
  >
    <Link
      to={to}
      postTo={postTo}
      className="flex w-full cursor-pointer items-center gap-2 px-2 py-1.5"
    >
      {icon && <div className="w-4 opacity-50">{icon}</div>}
      <div>{children}</div>
    </Link>
  </Item>
));
DropdownMenuLinkItem.displayName = Item.displayName;

const DropdownMenuButtonItem = forwardRef<
  ElementRef<typeof Item>,
  ComponentPropsWithoutRef<typeof Item> & {
    inset?: boolean;
    onClick?: () => void;
    icon?: ReactNode;
    formId?: string;
    type?: "button" | "submit";
    className?: string;
  }
>(({ className, inset, children, onClick, icon, formId, type, ...props }, ref) => (
  <Item
    asChild
    ref={ref}
    className={cn(
      "relative flex select-none items-center rounded-sm  text-sm  outline-none focus:bg-black/5 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ",
      inset && "pl-8",
      className
    )}
    {...props}
  >
    <button
      onClick={onClick}
      className="flex w-full cursor-pointer items-center gap-2 px-2 py-1.5 text-left"
      type={type}
      form={formId}
    >
      {icon && <div className="w-4 opacity-50">{icon}</div>}
      <div>{children}</div>
    </button>
  </Item>
));
DropdownMenuButtonItem.displayName = Item.displayName;

const DropdownMenuNavLinkItem = forwardRef<
  ElementRef<typeof Item>,
  ComponentPropsWithoutRef<typeof Item> & {
    inset?: boolean;
    to: string;
    className?: string;
  }
>(({ className, inset, children, to, ...props }, ref) => (
  <Item
    ref={ref}
    asChild
    className={cn(
      "relative flex cursor-default select-none items-center rounded-sm  text-sm font-medium outline-none focus:bg-surface data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ",
      inset && "pl-8",
      className
    )}
    {...props}
  >
    <NavLink to={to} className="w-full px-2 py-1.5">
      {({ isActive }) => (
        <div className="flex w-full items-center gap-2">
          <div className="flex-grow">{children}</div>
          <div className="flex w-6 flex-none items-center justify-center text-accent">
            {isActive && <BsCheck className="text-xl" />}
          </div>
        </div>
      )}
    </NavLink>
  </Item>
));
DropdownMenuNavLinkItem.displayName = Item.displayName;

const DropdownMenuCheckboxItem = forwardRef<
  ElementRef<typeof CheckboxItem>,
  ComponentPropsWithoutRef<typeof CheckboxItem> & {
    className?: string;
    checked?: boolean;
  }
>(({ className, children, checked, ...props }, ref) => (
  <CheckboxItem
    ref={ref}
    className={cn(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm font-medium outline-none focus:bg-black/5 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    )}
    checked={checked}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <ItemIndicator>
        <Check className="h-4 w-4" />
      </ItemIndicator>
    </span>
    {children}
  </CheckboxItem>
));
DropdownMenuCheckboxItem.displayName = CheckboxItem.displayName;

const DropdownMenuRadioItem = forwardRef<
  ElementRef<typeof RadioItem>,
  ComponentPropsWithoutRef<typeof RadioItem> & {
    className?: string;
  }
>(({ className, children, ...props }, ref) => (
  <RadioItem
    ref={ref}
    className={cn(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm font-medium outline-none focus:bg-surface data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <ItemIndicator>
        <Circle className="h-2 w-2 fill-current" />
      </ItemIndicator>
    </span>
    {children}
  </RadioItem>
));
DropdownMenuRadioItem.displayName = RadioItem.displayName;

const DropdownMenuLabel = forwardRef<
  ElementRef<typeof Label>,
  ComponentPropsWithoutRef<typeof Label> & {
    inset?: boolean;
    className?: string;
  }
>(({ className, inset, ...props }, ref) => (
  <Label
    ref={ref}
    className={cn("px-2 py-1.5 text-sm font-medium ", inset && "pl-8", className)}
    {...props}
  />
));
DropdownMenuLabel.displayName = Label.displayName;

const DropdownMenuSeparator = forwardRef<
  ElementRef<typeof Separator>,
  ComponentPropsWithoutRef<typeof Separator> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <Separator ref={ref} className={cn("-mx-1 my-1 h-px bg-border", className)} {...props} />
));
DropdownMenuSeparator.displayName = Separator.displayName;

const DropdownMenuShortcut = ({
  className,
  ...props
}: ComponentProps<"span"> & { className?: string }) => {
  return (
    <span className={cn("text-slate-500 ml-auto text-xs tracking-widest", className)} {...props} />
  );
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

export {
  DropdownMenu,
  DropdownMenuButtonItem,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuLinkItem,
  DropdownMenuNavLinkItem,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
};
